<template>
  <div id="main-menu" class="wsmainfull menu clearfix">
    <div class="wsmainwp clearfix">
      <!-- HEADER BLACK LOGO -->
      <div class="desktoplogo">
        <NuxtLink to="/" class="logo-black"
          ><img src="/assets/images/hilltop/logo.png" alt="logo"
        /></NuxtLink>
      </div>
      <!-- HEADER WHITE LOGO -->
      <div class="desktoplogo">
        <NuxtLink to="/" class="logo-white"
          ><img src="/assets/images/hilltop/logo.png" alt="logo"
        /></NuxtLink>
      </div>
      <!-- MAIN MENU -->
      <nav class="wsmenu clearfix" v-if="!shouldHideGetStartedButton">
        <ul class="wsmenu-list nav-theme">
          <!-- DROPDOWN SUB MENU 
          <li class="nl-simple">
            <NuxtLink to="/" class="h-link"> Home </NuxtLink>
          </li>-->
          <!-- SIMPLE NAVIGATION LINK -->
          
          <!--<li class="nl-simple">
            <NuxtLink to="/about" class="h-link"> About Us </NuxtLink>
          </li>
          <li class="nl-simple">
            <NuxtLink to="/faqs" class="h-link"> FAQs </NuxtLink>
          </li>
          <li class="nl-simple">
            <NuxtLink to="/contacts" class="h-link">Contact</NuxtLink>
          </li>-->
          <li class="nl-simple py-3" v-if="!shouldHideGetStartedButton">
            <v-btn
              color="secondary"
              size="large"
              ripple
              @click="goToQuote"
            >
              Get Started!
            </v-btn>
          </li>
        </ul>
      </nav>
      <!-- END MAIN MENU -->
    </div>
  </div>
</template>
<script lang="ts">
import { reactive } from "vue";
export default {
  setup() {
    // Get the current route
    const route = useRoute()
    const formsRoutePattern = [/^\/forms\//]

    // Compute whether the Get Started button should be hidden
    const shouldHideGetStartedButton = computed(() => {
      return formsRoutePattern.some(pattern => pattern.test(route.path))
    })

    const state = reactive({
      isOpen: [false, false],
    });
    const toggle = (index: any) => {
      state.isOpen[index] = !state.isOpen[index];
    };
    const goToQuote = () => {
      document.body.classList.remove("wsactive");
      return navigateTo({ path: '/forms/quote/start' });
    }

    const handleScroll = () => {
      const menu = document.getElementById("main-menu");
      const header = document.getElementById("header");
      if (window.pageYOffset > 100) {
        menu?.classList.add("scroll");
        header?.classList.add("scroll");
      } else {
        menu?.classList.remove("scroll");
        header?.classList.remove("scroll");
      }
    }

    onMounted(async () => { 
      window.addEventListener("scroll", handleScroll);
    })

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    })

    return {
      handleScroll,
      toggle,
      isOpen: state.isOpen,
      goToQuote,
      shouldHideGetStartedButton,
    };
  },
};
</script>
