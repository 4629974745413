<template>
  <div class="wsmobileheader clearfix">
    <span class="smllogo">
      <NuxtLink to="/">
        <img data-testid="logo" src="/assets/images/hilltop/logo.png" alt="mobile-logo"/>
      </NuxtLink>
    </span>
    <a id="wsnavtoggle" v-if="!shouldHideGetStartedButton" data-testid="mobile-menu-toggle" class="wsanimated-arrow" @click="toggleMobileMenu">
      <span></span>
    </a>
  </div>
</template>

<script lang="ts">
export default {
  setup() {
    // Get the current route
    const route = useRoute()
    const formsRoutePattern = [/^\/forms\//]

    // Compute whether the Get Started button should be hidden
    const shouldHideGetStartedButton = computed(() => {
      return formsRoutePattern.some(pattern => pattern.test(route.path))
    })

    const toggleMobileMenu = () => {
      // toggle body class "dark-mode"
      document.body.classList.toggle("wsactive");
    }

    const { syncQueryParamsOnNavigate } = useQueryParams();
    onMounted(() => {
      syncQueryParamsOnNavigate();
    });

    return {
      shouldHideGetStartedButton,
      toggleMobileMenu
    };
  },
};
</script>
