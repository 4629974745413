<template>
  <footer id="footer-3" class="footer">
    <hr class="divider mt-0" />
    <div class="container">

      <!-- FOOTER DIVIDER LINE -->
      <!-- BOTTOM FOOTER -->
      <div class="bottom-footer">
        <div class="row row-cols-1 row-cols-md-2 d-flex align-items-center">
          <!-- FOOTER COPYRIGHT -->
          <div class="col">
            <div class="footer-copyright">
              <p class="p-sm">
                &copy; 2024 Emerge. <span>All Rights Reserved</span>
              </p>
            </div>
          </div>
          <!-- FOOTER SECONDARY LINK -->
          <div class="col">
            <div class="bottom-secondary-link ico-15 text-end">
              <p>
                <a href="https://www.hilltopsecurities.com/disclosures/privacy-policy/" target="_blank" class="mx-2">Privacy Policy</a>
              </p>
            </div>
          </div>
        </div>
        <!-- End row -->
      </div>
      <!-- END BOTTOM FOOTER -->
    </div>

    
    <hr class="divider my-15" v-if="shouldShowAssurityTerms" />
    <div class="container"  v-if="shouldShowAssurityTerms">
      <div class="row align-items-center">
          <div class="col">
            <h5>Disclosure, Limitations and Exclusions</h5>
            <p>This policy may contain reductions of benefits, limitations and exclusions. <span @click="toggleLimitationsAndExclusions" class="custom-link-buttom" style="text-decoration: underline">Click here for standard Limitations and Exclusions</span>. Product availability, features, rates, limitations and exclusions may vary by state. For costs and complete details of the coverage, please contact your insurance representative or Assurity to review the policy for more information. The state specific policy form is the ultimate authority for any questions about this product.</p>  
            <p>Policy Form No. I H2203, Rider Form No. R I2204 and if made available, associated Form Nos.R I2205, R I2208 underwritten by Assurity Life Insurance Company, Lincoln, Nebraska.</p>
            <p>IMPORTANT NOTICE— This policy provides ACCIDENT INSURANCE ONLY. This policy DOES NOT PROVIDE COVERAGE FOR SICKNESS.</p>
            <p>Assurity reserves the right to order, at the company’s expense, evidence of insurability which the company feels is necessary for the prudent evaluation of the risk.</p>
            <p>Assurity is a marketing name for the mutual holding company Assurity Group, Inc. and its subsidiaries. Those subsidiaries include but are not limited to: Assurity Life Insurance Company and Assurity Life Insurance Company of New York. Insurance products and services are offered by Assurity Life Insurance Company in all states except New York. In New York, insurance products and services are offered by Assurity Life Insurance Company of New York, Albany, NY</p>
            
            <h5 class="mt-10 custom-link-buttom" @click="toggleLimitationsAndExclusions">
              Assurity Standard Policy Limitations and Exclusions (Form No. I H2203)
              <v-icon class="ml-5">{{ showLimitationsAndExclusions ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </h5>
            <v-expand-transition>
              <div v-if="showLimitationsAndExclusions" ref="refLimitationsAndExclusions">

                <p><strong>Note:</strong> Limitations and Exclusions may vary by state. The state specific policy form is the ultimate authority for any questions about this product.</p>

                <p><u>Guaranteed Renewable.</u> This policy form is guaranteed renewable, meaning the policyowner may continue the coverage provided through the end of the renewal period by payment of the required premiums when they are due. Terms of the policy cannot be changed while it is in force. Premiums for the policy may change, but any changes require prior regulatory approval and can be done on an insured class basis only.</p>

                <p><u>Misstatement of Age and/or Gender</u> - If any Insured Person’s age and/or gender has been misstated, an adjustment in premiums, coverage or both will be made based on the Insured Person’s correct age and/or gender. If, according to their correct age, the coverage provided by this policy would not have become effective or would have ceased, Our only liability during the period in which the Insured Person was not eligible for coverage shall be limited to a refund of premiums.</p>

                <p>This policy will not pay benefits for losses that are caused by or are the result of an Insured Person:</p>
                
                <ul class="custom-list">
                  <li>operating, learning to operate or serving as a crew member of any aircraft;</li>
                  <li>engaging in hang-gliding, hot air ballooning, bungee jumping, parachuting, scuba diving, sail gliding, parasailing, parakiting, mountain or rock climbing, B.A.S.E. jumping, sky diving or cave diving;</li>
                  <li>riding in or driving any motor-driven vehicle in an organized race, stunt show or speed test;</li>
                  <li>officiating, coaching, practicing for or participating in any semi-professional or professional competitive athletic contest for which any type of compensation or remuneration is received;</li>
                  <li>having a sickness, disease or infection, other than infection from an Injury occurring while this policy is in force;</li>
                  <li>being exposed to war or any act of war, declared or undeclared;</li>
                  <li>actively serving in any of the armed forces or units auxiliary thereto, including the National Guard or Reserve, except during the active duty training of less than 60 days;</li>
                  <li>suffering from a Mental and Nervous Disorder;</li>
                  <li>being addicted to drugs, including those prescribed by a Physician that are misused, or suffering from alcoholism;</li>
                  <li>being under the influence of an excitant, depressant, hallucinogen, narcotic, or any other drug or intoxicant (except those used as prescribed to the Insured Person by a Physician);</li>
                  <li>being intoxicated (as determined by the laws governing the operation of motor vehicles in the jurisdiction where loss occurs) or under the influence of an illegal substance;</li>
                  <li>having cosmetic surgery or other elective procedures that are not medically necessary;</li>
                  <li>committing or attempting to commit a felony;</li>
                  <li>being incarcerated in a penal institution or government detention facility;</li>
                  <li>participating in a riot, insurrection or rebellion;</li>
                  <li>engaging in an illegal occupation;</li>
                  <li>intentionally self-inflicting an injury; or</li>
                  <li>committing or attempting to commit suicide, while sane or insane.</li>
                  <li>No benefits, except the Initial Accident Treatment or Telemedicine benefit, will be payable for services provided outside of the United States.</li>
                </ul>

                <h5>Accidental Death Rider Standard Limitations and Exclusions (Form No. R I2204)</h5>

                <p>Limitations and exclusions for this rider are as listed for the policy.</p>

                <h5>Accident-Only Disability Income Rider Standard Limitations and Exclusions (Form No. R I2208)</h5>

                <p><u>Foreign Travel and Residency</u> - Up to a maximum of three Monthly Benefit  Amounts will be paid for any disability sustained or continued outside the United States or Canada.</p>

                <p>The Monthly Benefit will only be paid to the insured if the insured becomes totally disabled as the result of and within 180 days of a covered accident occurring prior to the insured’s 65<sup>th</sup> birthday.</p>

                <p>Benefits are not paid under this rider for Total Disabilities that result from losses subject to the exclusions listed for the policy.</p>

                <h5>Preventive Care Standard Limitations and Exclusions (Form No. R I2205)</h5>

                <p>Limitations and exclusions for this rider are as listed for the policy.</p>
              </div>
            </v-expand-transition>
          </div>
        </div>
    </div>
    <FooterGoToTop />
  </footer>
</template>

<script lang="ts">
export default {
  setup() {
    // Get the current route
    const route = useRoute()
    const hiddenRoutePatterns = [/^\/forms\//]

    // Compute whether the Get Started button should be hidden
    const shouldShowAssurityTerms = computed(() => {
      return hiddenRoutePatterns.some(pattern => pattern.test(route.path))
    })

    const refLimitationsAndExclusions = ref()
    const showLimitationsAndExclusions = ref(false)
    const toggleLimitationsAndExclusions = () => {
      showLimitationsAndExclusions.value = !showLimitationsAndExclusions.value
      if (showLimitationsAndExclusions.value) {
        refLimitationsAndExclusions.value.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
    }

    return {
      shouldShowAssurityTerms,
      showLimitationsAndExclusions,
      toggleLimitationsAndExclusions,
      refLimitationsAndExclusions
    }
  }
};
</script>
<style scoped>
  .custom-list {
    list-style-type: disc; /* or circle, square, etc. */
    list-style-position: outside; /* or outside */
    padding-left: 2rem;
  }

  .custom-link-buttom {
    cursor: pointer;
  }
</style>
